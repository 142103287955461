import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { Box } from "@mui/material";
import { CategoryItem } from "../../components";
import { useMemo } from "react";

export const CategoriesList = ({
  childrenlist,
  parentsList,
  loading,
  category,
  handleFetchCategories,
  isFilter,
}) => {
  const sortedParentsList = useMemo(
    () =>
      parentsList.sort((a, b) => {
        if (a.name === "All") return -1;
        if (b.name === "All") return 1;
        return a.name.localeCompare(b.name);
      }),
    [parentsList]
  );

  return (
    <Box
      sx={{
        opacity: loading ? 0.5 : 1,
        pointerEvents: loading ? "none" : "auto",
        height: isFilter ? "130px" : "100%",
        overflow: "overlay",
      }}
    >
      {sortedParentsList.map((cat) => (
        <CategoryItem
          key={cat?.id}
          id={cat?.id}
          ids={cat?.ids}
          name={cat?.name}
          hideNext
          fetchList={handleFetchCategories}
          isFilter={isFilter}
        />
      ))}

      {!!category?.name && (
        <CategoryItem
          id={parentsList?.[parentsList?.length - 1]?.id ?? null}
          ids={parentsList?.[parentsList?.length - 1]?.ids ?? null}
          name={category.name}
          hidePrev={!parentsList?.length}
          hideNext
          fetchList={handleFetchCategories}
          skipFetch={!parentsList?.length}
          isFilter={isFilter}
        />
      )}

      {childrenlist.map((cat) => (
        <CategoryItem
          key={cat?.id}
          id={cat?.id}
          ids={cat?.ids}
          name={cat?.name}
          hidePrev={true}
          hideNext={!(cat?._count?.childCategories > 0)}
          fetchList={handleFetchCategories}
          isChild
          isFilter={isFilter}
          productsCount={cat?._count?.products}
        />
      ))}
    </Box>
  );
};

CategoriesList.propTypes = {
  isFilter: bool,
  childrenlist: arrayOf(
    shape({
      id: number,
      name: string,
      _count: shape({
        childCategories: number,
      }),
    })
  ),
  parentsList: arrayOf(
    shape({
      id: number,
      name: string,
    })
  ),
  loading: bool,
  category: shape({
    name: string,
  }),
  handleFetchCategories: func,
};
CategoriesList.defaultProps = {
  childrenlist: [],
  parentsList: [],
  category: {},
  loading: false,
  handleFetchCategories: () => {},
};
