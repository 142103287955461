/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import * as React from "react";
import { SvgIcon } from "@mui/material";
export const ErrorIcon = ({ width = 24, height = 24, sx = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width, height, ...sx }}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12C24 5.371 18.627-.002 12-.002c-6.629 0-12.001 5.373-12.001 12 0 6.628 5.372 12.001 12 12.001m0-20a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0V5a1 1 0 0 1 1-1m0 15.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
