import {
  array,
  bool,
  func,
  instanceOf,
  number,
  oneOfType,
  string,
} from "prop-types";
import { Box, Typography } from "@mui/material";
import {
  ArrowNextIcon,
  ArrowPrevIcon,
} from "../../../../../../components/Icons";

export const CategoryItem = ({
  id,
  ids,
  name,
  hidePrev,
  hideNext,
  fetchList,
  isChild,
  skipFetch,
  isFilter,
  productsCount,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        cursor: !skipFetch ? "pointer" : "auto",
        width: "100%",
        height: "36px",
      }}
      onClick={() => {
        if (!skipFetch) fetchList(id, ids);
      }}
    >
      <Box width="16px">
        {!hidePrev && (
          <ArrowPrevIcon color="#000" width={6.05} height={10.59} />
        )}
      </Box>

      <Box flexGrow={1} width="80%">
        <Typography
          fontSize={isFilter ? 14 : 18}
          fontWeight={400}
          color={isChild ? "#8E8E8E" : "#000"}
          noWrap
        >
          {name}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={0.6}>
        {name === "All" || !productsCount ? null : (
          <Box
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "16px",
              bgcolor: "#F7F7F7",
              height: "17px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: "6px",
              fontSize: 12,
              fontWeight: 300,
              color: "#363531",
            }}
          >
            {productsCount}
          </Box>
        )}
        {hideNext ? (
          <Box width={6.05} />
        ) : (
          <ArrowNextIcon color="#000" width={6.05} height={10.59} />
        )}
      </Box>
    </Box>
  );
};

CategoryItem.propTypes = {
  id: oneOfType([number, string]),
  name: string,
  hidePrev: bool,
  hideNext: bool,
  fetchList: func,
  isChild: bool,
  skipFetch: bool,
  isFilter: bool,
  productsCount: number,
  ids: oneOfType([array, instanceOf(null)]),
};
CategoryItem.defaultProps = {
  id: "",
  name: "",
  hidePrev: false,
  hideNext: false,
  fetchList: () => {},
  isChild: false,
  skipFetch: false,
  isFilter: false,
};
