import { useCallback, useEffect, useState } from "react";
import {
  getByDistributorAndIdWithParentsAndChildrenService,
  getCategoriesWithParentsAndChildrenService,
} from "../../services/products";
import { useShowProductsWithoutLogIn } from "helpers/hooks";

const initialState = {
  loading: false,
  childrenlist: [],
  parentsList: [],
  currentCategory: {
    id: null,
    name: "All",
  },
  chosenCategoryId: null,
  chosenCategoryIds: null,
};

export const useCategories = ({ distributorId, manufacturer_ids }) => {
  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const [state, setState] = useState(initialState);
  const [firstLoadState, setFirstLoadState] = useState(initialState);

  const handleFetchCategories = async (id, ids) => {
    if (isShowProductsWithoutLogIn && !distributorId) return;

    const currentGetService = isShowProductsWithoutLogIn
      ? getByDistributorAndIdWithParentsAndChildrenService
      : getCategoriesWithParentsAndChildrenService;

    try {
      setState((prev) => ({
        ...prev,
        loading: true,
        chosenCategoryId: id,
        chosenCategoryIds: ids,
      }));
      const preparedDate = {
        id,
        distributorId,
      };

      if (!isShowProductsWithoutLogIn && distributorId) {
        preparedDate.distributor_ids = JSON.stringify([distributorId]);
      }

      if (!isShowProductsWithoutLogIn && manufacturer_ids) {
        preparedDate.manufacturer_ids = JSON.stringify(manufacturer_ids);
      }

      const data = await currentGetService(preparedDate);

      const dataForSave = {
        currentCategory: data?.current ?? {},
        childrenlist: data?.children ?? [],
        parentsList: data?.parents ?? [],
      };

      setState((prev) => ({ ...prev, ...dataForSave }));
      if (!id) setFirstLoadState((prev) => ({ ...prev, ...dataForSave }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleFlatCategories = useCallback(() => {
    const results = [];

    const traverseObjects = (currentObjects) => {
      for (const obj of currentObjects) {
        if (
          Array.isArray(obj.childCategories) &&
          obj.childCategories.length === 0
        ) {
          results.push(obj);
        } else if (
          Array.isArray(obj.childCategories) &&
          obj.childCategories.length > 0
        ) {
          traverseObjects(obj.childCategories);
        }
      }
    };

    traverseObjects(state.childrenlist);
    return results;
  }, [state.childrenlist]);

  useEffect(() => {
    handleFetchCategories(null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowProductsWithoutLogIn, distributorId]);

  return {
    state,
    setState,
    firstLoadState,
    handleFetchCategories,
    handleFlatCategories,
  };
};
