/* eslint-disable react/prop-types */
import { toast } from "react-toastify";
import { getTokenFromLocalStorage } from "../helpers/auth";
import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "components/Icons/Toast";
import { Box, Typography } from "@mui/material";

const cl = {
  wrapper: { maxWidth: "350px", display: "flex", alignItems: "center" },
  title: {
    color: "#FFF",
    fontWeight: 500,
    lineHeight: 1.3,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
  },
  callback: {
    ml: "8px",
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: 600,
    lineHeight: 1.1,
  },
  content: (hasIcon) => ({
    ml: hasIcon ? "-26px" : "8px",
    pt: "8px",
    pb: "12px",
    fontWeight: 400,
    letterSpacing: "0 !important",
  }),
};

const icon = {
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  error: <ErrorIcon />,
};

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const isMobile = detectMob();

const setIcon = (type) => {
  if (type === null) return false;
  if (!icon[type]) return icon["success"];

  return icon[type];
};

const CustomNotification = ({
  data = {
    title: "",
    content: "",
    undo: null,
    hasIcon: false,
  },
}) => {
  return (
    <Box>
      <Box sx={cl.wrapper}>
        <Typography sx={cl.title} noWrap>
          {data?.title}
        </Typography>
        {!!data?.undo?.callback && (
          <Box sx={cl.callback} onClick={data.undo.callback}>
            {data?.undo?.text}
          </Box>
        )}
      </Box>
      {!!data?.content && (
        <Box>
          <Box sx={cl.content(data?.hasIcon)}>{data.content}</Box>
        </Box>
      )}
    </Box>
  );
};

const styles = { fontWeight: 500 };

const successMobile = (msg, props) =>
  toast.success(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
    ...props,
  });

const successDesktop = (msg, options) => {
  const { icon, data, ...restOptions } = options || {};

  const preparedOptions = {
    ...((!!data || !!msg) && {
      data: {
        title: data?.title || msg || "",
        content: data?.content || "",
        hasIcon: !!setIcon(icon),
        ...(!!data?.undo?.callback && {
          undo: {
            text: data?.undo.text || "Undo",
            callback: data?.undo?.callback || null,
          },
        }),
      },
    }),
    position: "bottom-center",
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: !data?.undo?.callback,
    theme: "dark",
    style: { ...styles },
    ...restOptions,
    icon: setIcon(icon),
    closeButton: <CloseIcon />,
  };

  return toast.success(CustomNotification, preparedOptions);
};

const success = isMobile ? successMobile : successDesktop;

const warningMobile = (msg) =>
  toast.warning(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
  });

const warningDesktop = (msg, options) => {
  const { icon, ...restOptions } = options || {};

  return toast.warning(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: true,
    theme: "dark",
    style: { ...styles, backgroundColor: "#F39325" },
    ...restOptions,
    icon: setIcon(icon === undefined ? "warning" : icon),
    closeButton: <CloseIcon />,
  });
};

const warning = isMobile ? warningMobile : warningDesktop;

const errorMobile = (msg) => {
  const token = getTokenFromLocalStorage();
  if (token && window.navigator.onLine)
    return toast.error(msg || "Something went wrong!", errorConfig);
  return;
};

const errorDesktop = (err, options) => {
  const { icon, ...restOptions } = options || {};

  const msg = err?.response?.data?.message || err?.message || err;

  const token = getTokenFromLocalStorage();

  if (token && window.navigator.onLine)
    // return toast.error(msg || "Something went wrong!", errorConfig);
    return toast.error(msg || "Something went wrong!", {
      position: "bottom-center",
      progress: undefined,
      autoClose: 5000,
      hideProgressBar: true,
      theme: "colored",
      ...restOptions,
      style: { ...styles, backgroundColor: "#FF6969" },
      icon: setIcon(icon === undefined ? "error" : icon),
      closeButton: <CloseIcon />,
    });
  return;
};

const error = isMobile ? errorMobile : errorDesktop;

const errorConfig = {
  position: "bottom-center",
  theme: "colored",
  hideProgressBar: true,
  progress: undefined,
  autoClose: 5000,
};

export { success, error, warning, errorConfig };
export default {
  success,
  error,
  warning,
};
