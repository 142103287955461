import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";

import { Box, Button, InputAdornment, Paper, Typography } from "@mui/material";
import { useStyles } from "../styles";
import { PasswordCriteria, StyledTextField } from "components";
import { ActiveIcon } from "components/Icons";

import { bool, func, object, string } from "prop-types";

import { PASSWORD_CRITERIA } from "components/PasswordCriteria/PasswordCriteria.constants";

export const DesktopRestorePassword = ({
  logo,
  store,
  hasPasswordUpdated,
  formField,
  trigger,
  control,
  loading,
  handleSubmit,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="92vh"
      >
        <Paper
          sx={{
            width: "636px",
            px: "84px",
            borderRadius: "16px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          elevation={0}
          variant="outlined"
        >
          {/* Distributor Logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "76px",
              mb: "58px",
            }}
          >
            {logo ? logo?.logo : <Box height={store ? "116px" : "67px"} />}
          </Box>

          {hasPasswordUpdated ? (
            <>
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "18px",
                }}
              >
                <Typography fontSize={25} fontWeight={500} color="#5F6267">
                  Password Updated!
                </Typography>
              </Box>

              {/* Subtitle */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1.5,
                  mb: 10,
                }}
              >
                <Typography fontSize={18} fontWeight={400} color="#5F6267">
                  You password has been changed successfully.
                </Typography>
                <Typography fontSize={18} fontWeight={400} color="#5F6267">
                  Use your new password to{" "}
                  <Box
                    sx={{
                      color: "#409A65",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    component={Link}
                    to={store ? `/login/${store}` : "/login"}
                  >
                    log in
                  </Box>
                  .
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "18px",
                }}
              >
                <Typography fontSize={25} fontWeight={500} color="#5F6267">
                  Choose new password
                </Typography>
              </Box>

              {/* Email */}
              <Box mt="40px">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      disabled
                      fullWidth
                      margin="normal"
                      label="Email"
                      size="medium"
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            <ActiveIcon />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Box>

              {/* Password */}
              <Box>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      label="Enter new password"
                      type="password"
                      size="medium"
                      InputProps={{
                        className: classes.input,
                        autoComplete: "off",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      InputLabelProps={{ className: classes.inputLabel }}
                      error={error ? error.message : ""}
                      autoComplete="off"
                      inputProps={{
                        autocomplete: "new-password",
                        name: Math.random().toString(36).substring(2),
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (formField?.confirmPassword)
                          trigger(["confirmPassword"]);
                      }}
                    />
                  )}
                  name="password"
                  control={control}
                />
              </Box>
              {/* Confirm Password */}
              <Box>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      fullWidth
                      margin="normal"
                      label="Re-enter new password"
                      type="password"
                      size="medium"
                      InputProps={{
                        className: classes.input,
                        autoComplete: "off",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      InputLabelProps={{ className: classes.inputLabel }}
                      error={error ? error.message : ""}
                      autoComplete="off"
                      inputProps={{
                        autocomplete: "new-password",
                        name: Math.random().toString(36).substring(2),
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        if (formField?.password) trigger(["password"]);
                      }}
                    />
                  )}
                  name="confirmPassword"
                  control={control}
                />
              </Box>

              {/* Password criteria */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <PasswordCriteria
                  password={formField.password}
                  confirmPassword={formField.confirmPassword}
                />
              </Box>

              {/* Set password button */}
              <Box mt={2} mb={10}>
                <Button
                  disabled={
                    loading ||
                    PASSWORD_CRITERIA.some((criteria) => {
                      const { password, confirmPassword } = formField;
                      const completed = criteria.condition({
                        password,
                        confirmPassword,
                      });
                      return !completed;
                    })
                  }
                  fullWidth
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    height: "48px",
                    mt: 1,
                  }}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? "Loading" : "Reset password"}
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Box>
    </>
  );
};

DesktopRestorePassword.propTypes = {
  logo: object,
  store: string,
  loading: bool,
  hasPasswordUpdated: bool,
  formField: object,
  trigger: func,
  control: object,
  handleSubmit: func,
  onSubmit: func,
};
